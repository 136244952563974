import React, { Fragment } from 'react';
import logo from '../img/slam_e_unimi.png';
import logoMobile from '../img/logo_slam.png';
import logoPasqua from '../img/Pasqua_SLAM.png';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode, faCrown, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from "react-router";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isEggVisibile: false
        }
    }

    goToAdminPage = () => {
        this.props.history.push({ pathname: '/adminPage', state: { filters: this.props.filters, isActiveSearch: this.props.isChildSearchActive } })
    }

    componentDidMount() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }
        
        let today = new Date();
        let year = today.getFullYear();
        let Easter = new Date(year, 2, 25);
        let endEaster = new Date(year, 3, 25);
        if(today >= Easter && today < endEaster){
            this.setState({isEggVisibile: true})
        }
    }
      

    render() {
        return (
            <Fragment>
                <Row className='mb-3' style={{ textAlign: 'center' }}>
                    <Col lg={3}></Col>
                    <Col lg={8} sm={12}><img alt="logo" style={{ height: !this.state.isMobile ? '8rem' : '5rem' }} src={!this.state.isMobile ? logo : logoMobile}></img></Col>
                    <Col lg={1}></Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.isEggVisibile ? <img alt="logoPasqua" style={{height: '130px', position: 'absolute', bottom: '3px'}} src={logoPasqua}></img> : null}
                        <span className='logoItaliano'>UFF. LINGUA E CULTURA</span>
                    </Col>
                    <Col className='d-flex justify-content-end p-2'>
                        <Dropdown>
                            <Dropdown.Toggle style={{ backgroundColor: '#02336A', border: 'black' }} id="dropdown-basic">
                                <FontAwesomeIcon icon={faUser} /> {this.props.user}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.props.ruolo === 'ADMIN' || this.props.ruolo === 'ADMINISTRATOR' ? <Dropdown.Item onClick={this.goToAdminPage}><FontAwesomeIcon icon={faCrown} /> Admin Page</Dropdown.Item> : null}
                                <Dropdown.Item onClick={this.props.logout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                {/* <Row className='d-flex justify-content-end'>
                    <label className='mr-2' style={{ fontWeight: 'bold' }}>Utente: </label> {this.props.user}
                </Row>
                <Row className='d-flex justify-content-end'>
                    <Button variant='primary'>Logout <FontAwesomeIcon icon={faSignOutAlt} /></Button>
                </Row> */}
            </Fragment>)
    }
}

export default withRouter(Header);