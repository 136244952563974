import React, { Fragment } from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Accordion, Card, Row, Col } from 'react-bootstrap';
import { faArrowCircleDown, faArrowCircleUp, faArrowLeft, faIdCard, faCheck, faTimes, faHome } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import * as config from '../config/config';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

class StoriaStudente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accordionClicked: true,
            codiciCdlStorico: [],
            pageLoaded: false
        }
    }

    componentDidMount() {
        this.setState({ email: this.props.location.state.email })

        axios.get(`${process.env.REACT_APP_API_URL}/getStoricoStudente/${this.props.location.state.email}`, this.props.authParam).then((respStoricoStudente) => {
            let codiciCdlStorico = respStoricoStudente.data.map(ccs => {
                return ccs.COD_CDL;
            })

            axios.post(`${process.env.REACT_APP_API_URL}/getCoortiStorico`, codiciCdlStorico, this.props.authParam).then((resp) => {
                this.setState({ allStoricoStudente: respStoricoStudente.data, coortiStorico: resp.data, pageLoaded: true })
            }).catch((err) => {
                this.props.setErrors(err);
            })

        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    goHome = () => {
        this.setParentFilters().then(() => {
            this.props.history.go(-2);
        })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    render() {
        return (
            <Fragment>
                {/* <Button variant="primary mb-3" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Indietro </Button> */}
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goHome}><FontAwesomeIcon icon={faHome}/> Home </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={this.goBack}>
                        Dettaglio studente
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Storico studente
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Row><Col> <h3 className={'mt-2'}>STORICO  STUDENTE</h3> </Col> <Col className='pt-3'> <span className='customLabel'>Email: </span>{this.state.email}</Col></Row>
                {this.state.pageLoaded && this.state.allStoricoStudente.length > 0 ?
                    this.state.allStoricoStudente.map((storicoStudente, idx) =>
                        <Accordion key={idx} defaultActiveKey="1" className='mt-3'>
                            <Card>
                                <Card.Header>
                                    <Row className='d-flex justify-content-between'>
                                        <Col>
                                            <h5>Matricola: {storicoStudente.MATRICOLA} - CdL: {storicoStudente.COD_CDL} - Stato: {storicoStudente.STATUS_STUDENTE}</h5>
                                        </Col>
                                        <Col className='text-right'>
                                            <Accordion.Toggle as={Button} onClick={() => this.setState({ accordionClicked: !this.state.accordionClicked })} variant="link" eventKey="0">
                                                <FontAwesomeIcon icon={this.state.accordionClicked ? faArrowCircleUp : faArrowCircleDown} size={'lg'} />
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Card className='mt-2 mb-2' border={'dark'}>
                                            <Card.Header className='blueUnimiBG'>
                                                <Row>
                                                    <Col xl={8}><h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faIdCard} /> ANAGRAFICA STUDENTE</h4></Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Row className='p-0'>
                                                        <Col xl={12}><span className='customLabel'>Stato studente:</span> {storicoStudente.STATUS_STUDENTE}</Col>
                                                        <Col xl={6}><span className='customLabel'>Matricola:</span> {storicoStudente.MATRICOLA}</Col>
                                                        <Col xl={6}>
                                                            <span className='customLabel'>Invio E-mail PT</span> {storicoStudente.CONVOCAZIONE_PT === 'Y' ? <FontAwesomeIcon color={'green'} icon={faCheck} size={'lg'} /> : <FontAwesomeIcon color={'red'} icon={faTimes} size={'lg'} />}
                                                        </Col>
                                                        <Col xl={6}><span className='customLabel'>Codice corso di laurea:</span> {storicoStudente.COD_CDL}</Col>
                                                        <Col xl={6}><span className='customLabel'>Corso di laurea:</span> {storicoStudente.CDL}</Col>
                                                        <Col xl={6}><span className='customLabel'>Cognome:</span> {storicoStudente.COGNOME}</Col>
                                                        <Col xl={6}><span className='customLabel'>Nome:</span> {storicoStudente.NOME}</Col>
                                                        <Col xl={6}><span className='customLabel'>Codice fiscale:</span> {storicoStudente.COD_FISCALE}</Col>
                                                        <Col xl={6}><span className='customLabel'>E-mail:</span> {storicoStudente.EMAIL}</Col>
                                                        <Col xl={6}><span className='customLabel'>Livello:</span> {config.decodeLivello[storicoStudente.LIVELLO]}</Col>
                                                        <Col xl={6}><span className='customLabel'>Uscita Cdl:</span> {this.state.coortiStorico.find(cooStorico => cooStorico.COD_CDL_COORTI === storicoStudente.COD_CDL) ? this.state.coortiStorico.find(cooStorico => cooStorico.COD_CDL_COORTI === storicoStudente.COD_CDL).USCITA : '-'}</Col>
                                                        <Col xl={6}><span className='customLabel'>Data immatricolazione:</span> {moment(storicoStudente.DATA_ISCRIZIONE).format('DD/MM/YYYY')}</Col>
                                                        <Col xl={6}><span className='customLabel'>Curriculum:</span> {storicoStudente.CV_CDL ? storicoStudente.CV_CDL : '-'}</Col>

                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>) : null}

            </Fragment>)
    }

}

export default withRouter(StoriaStudente);