export const encodeTipoEsito = {
    "PLACEMENT TEST": 0,
    "TEST FINALE": 1,
    "CERTIFICAZIONE": 2,
    "TENTATIVI SUCCESSIVI": 3,
    "TEST DI INGRESSO": 4,
    "LIVELLO TRIENNALE": 5,
}

export const encodeLivello = {
    "A1": 0,
    "A2": 1,
    "B1": 2,
    "B2": 3,
    "C1": 4,
    "C2": 5,
    "ASSENTE": 6,
    "ANNULLATO": 7,
    "NON SUPERATO": 8,
    "CONVALIDA": 9,
    "DECADUTO": 10,
    "NON AMMESSO": 11,
    "NON GESTITO" : 12,
    "A0": 13,
    "RINUNCIA CORSO SLAM" : 14
}

export const decodeTipoEsito = {
    0: "PLACEMENT TEST",
    1: "TEST FINALE",
    2: "CERTIFICAZIONE",
    3: "TENTATIVI SUCCESSIVI",
    4: "TEST DI INGRESSO",
    5: "LIVELLO TRIENNALE"
}

export const decodeLivello = {
    0: "A1",
    1: "A2",
    2: "B1",
    3: "B2",
    4: "C1",
    5: "C2",
    6: "ASSENTE",
    7: "ANNULLATO",
    8: "NON SUPERATO",
    9: "CONVALIDA",
    10: "DECADUTO",
    11: "NON AMMESSO",
    12: "NON GESTITO",
    13: "A0",
    14: "RINUNCIA CORSO SLAM"
}


export const mapIdStudente = {
    "RINUNCIA": 0,
    "TRASFERIMENTO": 1,
    "FINE CARRIERA": 2,
    "ATTIVO": 3,
    "SOSPENSIONE" : 4,
    "DOPPIA ISCRIZIONE": 5,
    "LAUREATO": 6,
    "RICHIEDENTE VISTO": 7
}

export const mapStatoStudente = {
    0: "RINUNCIA",
    1: "TRASFERIMENTO",
    2: "FINE CARRIERA",
    3: "ATTIVO",
    4: "SOSPENSIONE",
    5: "DOPPIA ISCRIZIONE",
    6: "LAUREATO",
    7: "RICHIEDENTE VISTO"
}