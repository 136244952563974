import React from 'react';
import DataTable from 'react-data-table-component';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import * as config from '../config/config';



// createTheme('unimi', )

class TableResults extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      columns : [
        {
          name: 'MATRICOLA',
          selector: 'MATRICOLA',
          sortable: true,
          minWidth: '150px',
          cell: row => <div>{row.MATRICOLA}</div>,
        },
        {
          name: 'COD. CDL',
          selector: 'COD_CDL',
          sortable: true,
          cell: row => <div title={row.COD_CDL}>{row.COD_CDL}</div>,
          // maxWidth: '5px'
        },
        {
          name: 'CORSO DI LAUREA',
          selector: 'CDL',
          sortable: true,
          cell: row => <div title={row.CDL}>{row.CDL}</div>,
          minWidth: '200px',
        },
        {
          name: 'COGNOME',
          selector: 'COGNOME',
          sortable: true,
          cell: row => <div title={row.COGNOME}>{row.COGNOME}</div>,
        },
        {
          name: 'NOME',
          selector: 'NOME',
          sortable: true,
          cell: row => <div title={row.NOME}>{row.NOME}</div>,
        },
        {
          name: 'LIVELLO',
          selector: 'LIVELLO',
          sortable: true,
          minWidth: '1rem',
          cell: row => <div>{config.decodeLivello[row.LIVELLO]}</div>
        },
        {
          name: 'DATA IMMATRICOLAZIONE',
          selector: 'DATA_ISCRIZIONE',
          sortable: true,
          maxWidth: '1rem',
          cell: row => <div>{moment(row.DATA_ISCRIZIONE).format('DD/MM/YYYY')}</div>
        },
        {
          selector: 'DETTAGLIO',
          sortable: false,
          right: true,
          maxWidth: '0.5rem',
          cell: row => <FontAwesomeIcon style={{ color: '#02336A' }} icon={faListAlt} onClick={() => this.goToDetail(row)} className={'curosorHover'} size={'2x'} title='Visualizza dettaglio' />
        }
      ],
      mobileCol : [
        {
          name: 'MATRICOLA',
          selector: 'MATRICOLA',
          sortable: true,
          maxWidth: '1rem',
          cell: row => <div>{row.MATRICOLA}</div>,
        },
        {
          name: 'COD. CDL',
          selector: 'COD_CDL',
          sortable: true,
          cell: row => <div title={row.COD_CDL}>{row.COD_CDL}</div>,
          maxWidth: '1rem'
        },
        {
          name: 'COGNOME',
          selector: 'COGNOME',
          sortable: true,
          cell: row => <div title={row.COGNOME}>{row.COGNOME}</div>,
        },
        {
          name: 'NOME',
          selector: 'NOME',
          sortable: true,
          cell: row => <div title={row.NOME}>{row.NOME}</div>,
        },
        {
          selector: 'DETTAGLIO',
          sortable: false,
          right: true,
          maxWidth: '0.5rem',
          cell: row => <FontAwesomeIcon style={{ color: '#02336A' }} icon={faListAlt} onClick={() => this.goToDetail(row)} className={'curosorHover'} size={'2x'} title='Visualizza dettaglio' />
        }
      ]
    }
  }

  componentDidMount() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({ isMobile: true })
    }
  }

  conditionalRowStyles = [
    {
      when: row => row.STATUS_IMPORT === 'CONFLITTO',
      style: { backgroundColor: 'rgba(	255, 213, 0, 0.6)' }
    },
    {
      when: row => row.STATUS_IMPORT === 'MANCANTE',
      style: { backgroundColor: 'rgba(134, 238, 255, 0.6)' }
    }
  ]

  

  goToDetail = (studente) => {
    // alert(ID);
    this.props.history.push({ pathname: '/details', state: { studente: studente, filters: this.props.filters, isActiveSearch: this.props.isActiveSearch } })
  }


  render() {
    return (<DataTable
      className={'tableCustomStyle mt-5'}
      columns={this.state.isMobile ? this.state.mobileCol : this.state.columns}
      data={this.props.data}
      pagination={true}
      paginationPerPage={this.props.paginationPerPage}
      striped
      // onChangePage={this.props.onChangePage}
      highlightOnHover
      responsive
      conditionalRowStyles={this.conditionalRowStyles}
    />)
  }
}

export default withRouter(TableResults);