import React from 'react';
import { Modal, Button, Alert, } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import Loader from '../components/Loader';
import DataTable from 'react-data-table-component';
import { saveAs } from 'file-saver';
import moment from 'moment';


class ModalMailingList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            reload: false,
            
        }

        this.columns= [
            {
                name: 'Tipo Corso',
                selector: 'tipoCorso',
                sortable: false,
                cell: row => <div>{row.tipoCorso}</div>,
            },
            {
                name: 'Numero',
                selector: 'numero',
                // sortable: true,
                cell: row => <div>{row.numero}</div>,
            },
            {
                name: '',
                selector: 'copyButton',
                sortable: true,
                cell: (row, index) => <div><Button className='mr-2' id={"MLButton-"+index} title="Copia mailing list" variant="primary mr-2" onClick={() => this.copyText(index)}><span>Copia <FontAwesomeIcon icon={faCopy} /></span></Button> <Button title="Scarica mailing list" variant="success mr-2" onClick={() => this.callEstrazioneMailingList(index)}><span>Scarica <FontAwesomeIcon icon={faFileDownload} /></span></Button></div>,
            }
        ]

    }

    componentDidMount() {
        this.setState({ isLoading: true })
        axios.get(`${process.env.REACT_APP_API_URL}/getMailingList`, this.props.authParam).then((resp) => {
            this.setState({ respMailingList: resp.data.elements, resp: resp.data , isLoading: false })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    copyText = (index) => {
        var copyhelper = document.createElement("input");
        copyhelper.className = 'copyhelper'
        document.body.appendChild(copyhelper);
        copyhelper.value = this.state.respMailingList[index].mailingList;
        copyhelper.select();
        document.execCommand("copy");
        document.body.removeChild(copyhelper);

        this.setState({index: index, alertCopyMessage: true})
        setTimeout(() => {
            this.setState({alertCopyMessage: false})
        }, 2000)
    }

    callEstrazioneMailingList = (index) => {
        var blob = new Blob([this.state.respMailingList[index].mailingList], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `SLAM-${moment(new Date()).format("DD.MM.YYYY")}-${this.state.respMailingList[index].tipoCorso}.csv`);
    }

    

   

    render() {
        return (
            <Modal show={this.props.showModalMailingList} onHide={this.props.closeModalMailingList} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{color: 'white'}}>
                        MAILING LIST
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {this.state.alertCopyMessage  ? <Alert className='mt-3' variant={'success'}>
                    MAILING LIST "<b>{this.state.respMailingList[this.state.index].tipoCorso}</b>" copiata
                </Alert>: null}
                {this.state.isLoading ?
                        <Loader /> : 
                        <DataTable
                                    className={'tableCustomHeader'}
                                    columns={this.columns}
                                    data={this.state.respMailingList}
                                    striped
                                    responsive
                                    noHeader={true}
                                />
                        }
                        
                <div className='mt-4'>Gli studenti iscritti esclusi K0K sono: {this.state.resp ? <b>{this.state.resp.excludedElements}</b> : ''}</div>
                <div className='mt-2'>Gli studenti a frequenza libera degli anni precedenti esclusi sono: {this.state.resp ? <b>{this.state.resp.frequenzaLiberaEsclusi}</b> : ''}</div>
                <div className='mt-2'>Gli studenti presi in considerazione sono: {this.state.resp ? <b>{this.state.resp.totalElements}</b> : ''}</div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={this.props.closeModalMailingList}>
                        Annulla
                    </Button>
                    {/* <Button onClick={null} variant="primary">
                        Salva
                    </Button> */}
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalMailingList;