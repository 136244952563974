import React from 'react';
import axios from 'axios';
import TableResults from './TableResults';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faFileDownload, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, FormControl, InputGroup, Button, Spinner } from 'react-bootstrap';
import SectionImportaCsv from '../sections/SectionImportaCsv';
import { FiltriAvanzati } from './FiltriAvanzati';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Select, { components } from 'react-select';
import Loader from '../components/Loader';


const loader = <Spinner animation="border" size="sm" role="status">
    <span className="sr-only">Loading...</span>
</Spinner>

class RicercaMat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            matData: null,
            estrazioneLoading: false,
            filters: [
                { name: 'anno_accademico', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'matricola', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'nome', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'cognome', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'email', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                // { name: 'cdl', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'cod_cdl', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                // { name: 'cod_fiscale', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'livello', value: '', advancedSearch: true, isRequiredFields: false, type: 'int' },
                { name: 'tipologia_esito', value: '', advancedSearch: true, isRequiredFields: false, type: 'int' },
                { name: 'startDataCorso', value: '', advancedSearch: true, isRequiredFields: false, type: 'date' },
                { name: 'endDataCorso', value: '', advancedSearch: true, isRequiredFields: false, type: 'date' },
                // { name: 'startDataImm', value: '', advancedSearch: false, isRequiredFields: false, type: 'date' },
                // { name: 'endDataImm', value: '', advancedSearch: false, isRequiredFields: false, type: 'date' },
                { name: 'descrizione', value: '', advancedSearch: true, isRequiredFields: false, type: 'string' },
                { name: 'status_studente', value: 'ATTIVO', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'status_import', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'area', value: '', advancedSearch: false, isRequiredFields: false, type: 'string' },
                { name: 'obb_o_fac', value: '', advancedSearch: true, isRequiredFields: false, type: 'string' },
            ],
            icon: faChevronDown,
            tipiEsiti: null,
            defaultVisibleAccordion: null,
            loadPage: false,
            isLoading: false,
            isActiveSearch: false,
            paginationPerPage: 10,
            isMobile: false,
            initalAnnoAccademico: ''
        }
    }

    componentDidMount() {

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }

        axios.get(`${process.env.REACT_APP_API_URL}/getAnniAccademiciApplicativo`, this.props.authParam).then((resp) => {
            if(resp && resp.data.length > 0){
                this.state.filters.find(f => f.name === 'anno_accademico').value = resp.data && resp.data[0].ANNO_ACCADEMICO ? resp.data[0].ANNO_ACCADEMICO : this.getAnnoAccademicoCorrente();
                
                if (this.props.parentFilters && this.props.parentFilters.length > 0) {
                    let searchParamsAndType = this.getSearchParamsAndType(this.props.parentFilters);
                    let defaultVisibleAccordion = this.state.defaultVisibleAccordion;
                    if (searchParamsAndType[0] === 'ricercaAvanzata') {
                        defaultVisibleAccordion = "0";
                    }
                    this.setState({ filters: this.props.parentFilters, defaultVisibleAccordion: defaultVisibleAccordion }, () => {
                        this.search();
                    })
                }
                
                this.setState({ anniAccademici: resp.data, initalAnnoAccademico: resp.data[0].ANNO_ACCADEMICO ? resp.data[0].ANNO_ACCADEMICO : this.getAnnoAccademicoCorrente() })
            }
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getTipoEsiti`, this.props.authParam).then((resp) => {
            this.setState({ tipiEsiti: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        // axios.get(`${process.env.REACT_APP_API_URL}/getAnniAccademiciIscrizione`, this.props.authParam).then((resp) => {
        //     this.setState({ anniAccademiciIScrizione: resp.data })
        // }).catch((err) => {
        //     this.props.setErrors(err);
        // })

        axios.get(`${process.env.REACT_APP_API_URL}/getLivelli`, this.props.authParam).then((resp) => {
            this.setState({ livelli: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getCodCdl`, this.props.authParam).then((resp) => {
            let codici = resp.data.map(c => { return { value: c.COD_CDL_COORTI, label: c.COD_CDL_COORTI } })
            this.setState({ codiciCdl: codici })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getStatiStudente`, this.props.authParam).then((resp) => {
            this.setState({ statiStudente: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getAree`, this.props.authParam).then((resp) => {
            this.setState({ aree: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })




        this.setState({ loadPage: true })


    }

    getAnnoAccademicoCorrente = () => {
        let date = new Date();
        let actualYear = date.getFullYear();
        let actualMonth = date.getMonth();
        let annoAccademicoCorrente = '';
        switch(actualMonth) {
            case 0:
                annoAccademicoCorrente = (actualYear -1).toString() + '/' + actualYear.toString() 
              break;
            case 1:
                annoAccademicoCorrente = (actualYear -1).toString() + '/' + actualYear.toString()
              break;
            case 2:
                annoAccademicoCorrente = (actualYear -1).toString() + '/' + actualYear.toString()
            break;
            default:
                annoAccademicoCorrente = actualYear.toString() + '/' + (actualYear + 1).toString();
          }

        return annoAccademicoCorrente.toString();
    }


    clean = () => {
        let filters = this.state.filters;
        filters.forEach(f => f.name === 'status_studente' ? f.value = 'ATTIVO' : f.name === 'anno_accademico' ? filters.find(f=> f.name === 'anno_accademico').value = this.state.initalAnnoAccademico :f.value = '')
        this.setState({ filters: filters, matData: null, selectedOptionValues: [], isActiveSearch: false, isLoading: false })
    }

    search = () => {
        let searchParamsAndType = this.getSearchParamsAndType(this.state.filters);
        this.setState({ isLoading: true, matData: null })
        if (this.checkDate() && searchParamsAndType[0] === 'ricercaAvanzata') {
            alert('Selezionare le date di svolgimento')
        } else {
            let filtersToCall = this.state.filters.map(function (f) {
                return {
                    name: f.name,
                    value: f.value,
                    type: f.type
                }
            })
            axios.post(`${process.env.REACT_APP_API_URL}/${searchParamsAndType[0]}`, filtersToCall, this.props.authParam).then((resp) => {
                this.setState({ matData: resp.data, isLoading: false, isActiveSearch: true });
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }

    }

    checkDate = () => {
        if (this.state.filters.find(f => f.name === 'startDataCorso').value && !this.state.filters.find(f => f.name === 'endDataCorso').value) return true;
        else if (!this.state.filters.find(f => f.name === 'startDataCorso').value && this.state.filters.find(f => f.name === 'endDataCorso').value) return true;
        else return false;
    }

    getSearchParamsAndType = (filters) => {
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].value !== '' && filters[i].advancedSearch) {
                return ['ricercaAvanzata'];
            }
        }

        return ['ricerca']
    }


    handleAccordion = () => {
        let icon = this.state.icon === faChevronDown ? faChevronUp : faChevronDown;
        this.setState({ icon: icon })
    }

    onChangeValue = (event, name) => {
        let filters = this.state.filters;
        let specials = /[*|\":<>[\]{}`\\()';@&$]/;
        if (!specials.test(event.target.value && event.target.value.toUpperCase() === "INTERFACOLTA' SCIENTIFICHE")) {
            filters.find(f => f.name === name).value = event.target.value;
            this.setState({ filters: filters })
        }
    }

    // onChangeValueSelect = (event, name) =>  {
    //     let filters = this.state.filters;
    //     let id = parseInt(event.target.value);
    //     debugger
    //     filters.find(f => f.name === name).value = config.encodeTipoEsito[id];
    //     this.setState({ filters: filters })
    // }

    changeDate = (date, type) => {
        let filters = this.state.filters;
        console.log(date)
        let dateFormatted = date ? date : '';
        if (type === 'S') filters.find(f => f.name === 'startDataCorso').value = dateFormatted; else filters.find(f => f.name === 'endDataCorso').value = dateFormatted;
        this.setState({ filters: filters })
    }

    changeDateImm = (date, type) => {
        let filters = this.state.filters;
        let dateFormatted = date ? date : '';
        if (type === 'S') filters.find(f => f.name === 'startDataImm').value = dateFormatted; else filters.find(f => f.name === 'endDataImm').value = dateFormatted;
        this.setState({ filters: filters })
    }

    callEstrazione = () => {
        let searchParamsAndType = this.getSearchParamsAndType(this.state.filters);
        let filtersToCall = this.state.filters.map(function (f) {
            return {
                name: f.name,
                value: f.value,
                type: f.type
            }
        })
        this.setState({ estrazioneLoading: true })
        axios.post(`${process.env.REACT_APP_API_URL}/callEstrazione${searchParamsAndType[0]}`, filtersToCall, {
            responseType: 'arraybuffer',
            headers: this.props.authParam.headers
        }).then((resp) => {
            this.setState({ estrazioneLoading: false })
            var blob = new Blob([resp.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, `Estrazione_${moment(new Date()).format("DD MM YYYY hh:mm:ss")}.xlsx`);
        })
    }

    downloadFile = (resp, fileName) => {
        // const link = document.createElement('a')
        // link.href = 'data:text/csv,' + encodeURIComponent(data)
        // link.download = fileName
        // link.click()
        const blob = new Blob([resp], { type: "applicationi/xlsx" });
        saveAs(blob, fileName);

        // var blob = new Blob([s2ab(atob(resp))], {
        //     type: ''
        // });
        // saveAs(blob, fileName);

        // function s2ab(s) {
        //     var buf = new ArrayBuffer(s.length);
        //     var view = new Uint8Array(buf);
        //     for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        //     return buf;
        //   }
    }

    onChangeSelect = (value, name) => {
        let filters = this.state.filters;
        let valueMod = value.map(el => el.value).toString();
        filters.find(f => f.name === name).value = valueMod;
        this.setState({ filters: filters, selectedOptionValues: value })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    }

    onChangePage = (actualNumberPage) => {
        let matData = this.state.matData;
        this.setState({ isLoading: true, matData: null })
        let matDataLength = this.state.matData ? this.state.matData.length : 0;
        if (matDataLength > 0) {
            let actualElements = actualNumberPage * this.state.paginationPerPage;
            setTimeout(() => {
                this.setState({ isLoading: false, matData: matData })
            }, 300)

        }
    }


    render() {

        const MultiValueContainer = props => {
            return (
                <components.MultiValueContainer {...props} />
            );
        };

        return (
            <div className='mb-3' onKeyDown={this.onKeyDown}>
                {!this.props.isReadOnly && !this.state.isMobile ? <SectionImportaCsv filters={this.state.filters} isActiveSearch={this.state.isActiveSearch} /> : null}
                <Row>
                    <Col lg={3} className="mb-3">
                        <label className='customLabel' htmlFor="basic-url">Anno accademico</label>
                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'anno_accademico')}
                            value={this.state.filters.find((f) => f.name === 'anno_accademico').value}>
                            <option></option>
                            {this.state.anniAccademici ? this.state.anniAccademici.map(ac => <option value={ac.ANNO_ACCADEMICO} key={ac.ANNO_ACCADEMICO}>{ac.ANNO_ACCADEMICO}</option>) : <option></option>}
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Matricola</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                maxLength={6}
                                onChange={(e) => this.onChangeValue(e, 'matricola')}
                                value={this.state.filters.find((f) => f.name === 'matricola').value}
                                placeholder="Ricerca matricola"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Cognome</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'cognome')}
                                value={this.state.filters.find((f) => f.name === 'cognome').value}
                                placeholder="Ricerca cognome"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Nome</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'nome')}
                                value={this.state.filters.find((f) => f.name === 'nome').value}
                                placeholder="Ricerca nome"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col>
                    {/* <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">Corso di laurea</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'cdl')}
                                value={this.state.filters.find((f) => f.name === 'cdl').value}
                                placeholder="Ricerca corso"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col> */}

                    {/* <Col lg={3}>
                        <label className='customLabel' htmlFor="basic-url">E-mail</label>
                        <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={this.onChangeEmail}
                                value={this.state.email}
                                placeholder="Ricerca per e-mail"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup>
                    </Col> */}
                </Row>
                <Row>
                    <Col lg={6}>
                        <label className='customLabel' htmlFor="basic-url">Cod. CdS</label>
                        {/* <InputGroup className="mb-3">
                            <FormControl
                                aria-label="Default"
                                onChange={(e) => this.onChangeValue(e, 'cod_cdl')}
                                value={this.state.filters.find((f) => f.name === 'cod_cdl').value}
                                placeholder="Ricerca codice"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </InputGroup> */}
                        <Select
                            value={this.state.selectedOptionValues}
                            closeMenuOnSelect={false}
                            isMulti
                            components={{ MultiValueContainer }}
                            styles={{
                                multiValue: base => ({
                                    ...base,
                                    border: `2px dotted blue}`,
                                }),
                            }}
                            placeholder='Ricerca Cds'
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(value) => this.onChangeSelect(value, 'cod_cdl')}
                            options={this.state.codiciCdl}
                        />
                    </Col>
                    <Col lg={3} className="mb-3">
                        <label className='customLabel' htmlFor="basic-url">Stato studente</label>
                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'status_studente')}
                            value={this.state.filters.find((f) => f.name === 'status_studente').value}>
                            <option></option>
                            {this.state.statiStudente ? this.state.statiStudente.map(statoSt => <option value={statoSt.DESC_STATO} key={statoSt.ID_STATO_STUDENTE}>{statoSt.DESC_STATO}</option>) : <option></option>}
                        </select>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <label className='customLabel' htmlFor="basic-url">Area</label>
                        <select className='form-control' onChange={(e) => this.onChangeValue(e, 'area')}
                            value={this.state.filters.find((f) => f.name === 'area').value}>
                            <option></option>
                            {this.state.aree ? this.state.aree.map(area => <option value={area.AREA} key={area.AREA}>{area.AREA}</option>) : <option></option>}
                        </select>
                    </Col>
                </Row>
                {this.state.loadPage ?
                    <FiltriAvanzati
                        handleAccordion={this.handleAccordion}
                        onChangeValue={this.onChangeValue}
                        onChangeValueSelect={this.onChangeValueSelect}
                        filters={this.state.filters}
                        icon={this.state.icon}
                        date={this.state.date}
                        changeDateImm={this.changeDateImm}
                        changeDate={this.changeDate}
                        tipiEsiti={this.state.tipiEsiti}
                        livelli={this.state.livelli}
                        defaultVisibleAccordion={this.state.defaultVisibleAccordion}
                    /> : null}
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Button className='mr-2' variant="primary" onClick={this.search}>Ricerca <FontAwesomeIcon icon={faSearch} /></Button>
                        <Button variant="secondary mr-2" disabled={!this.state.matData || this.state.matData.length === 0 || this.state.estrazioneLoading} onClick={this.callEstrazione}> Scarica risultati {this.state.estrazioneLoading ? loader : <FontAwesomeIcon icon={faFileDownload} />}</Button>
                        <Button variant="danger" onClick={this.clean}>Pulisci risultati <FontAwesomeIcon icon={faTrash} /></Button>
                    </Col>
                </Row>
                {this.state.matData ? <TableResults onChangePage={this.onChangePage} filters={this.state.filters} data={this.state.matData} isActiveSearch={this.state.isActiveSearch} paginationPerPage={this.state.paginationPerPage} /> : this.state.isLoading ? <Loader customStyle={{ marginTop: '5px' }} /> : null}
            </div>)
    }
}

export default withRouter(RicercaMat);