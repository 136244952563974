import React from 'react';
import { Modal, Button, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import * as config from '../config/config';
import DatePicker from 'react-datepicker'
import { registerLocale } from "react-datepicker";
import moment from 'moment';
import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it); // register it with the name you want



class ModalUserData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataModal: props.dataModal,
            type: props.typeModal,
            tipologiaEsito: props.tipologiaEsito
        }
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/getCodCdl`, this.props.authParam).then((resp) => {
            this.setState({ codiciCdl: resp.data })
            // this.props.hideAlertConflitto();
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getStatiStudente`, this.props.authParam).then((resp) => {
            this.setState({ statiStudente: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    saveData = () => {

        if(this.state.type === 'anagrafica'){
            let params = {
                idStudente: this.props.idStudente,
                fields: this.state.dataModal.fields,
                idDoppiaImmatricolazione: this.props.idDoppiaImmatricolazione
            }

            //convert number to label
            let id_stato_studente = this.state.dataModal.fields.find(f => f.name === 'stato_studente').value;
            params.fields.find(f => f.name === 'stato_studente').value = config.mapStatoStudente[id_stato_studente];

            axios.post(`${process.env.REACT_APP_API_URL}/saveStudente`, params, this.props.authParam).then((resp) => {
                let codCdl = resp.data.COD_CDL ? resp.data.COD_CDL : null;
                this.props.closeModalMod(true, codCdl, this.props.idStudente);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            //valutazioni
            let params = [
                {ID_VALUTAZIONE: this.props.id_valutazione, ID_STUDENTE: this.props.idStudente, TIPOLOGIA_ESITO: this.state.tipologiaEsito, LIVELLO_VAL: this.state.dataModal.fields.find(f=> f.name === 'livello').value, DATA_SVOLGIMENTO: this.state.dataModal.fields.find(f=> f.name === 'data').value ? moment(this.state.dataModal.fields.find(f=> f.name === 'data').value).format('DD/MM/YYYY'): null, LIVELLO_PRECEDENTE: this.props.livelloPrecedente === 'null' ? null : this.props.livelloPrecedente, DESCRIZIONE: this.state.dataModal.fields.find(f=> f.name === 'descrizione') ? this.state.dataModal.fields.find(f=> f.name === 'descrizione').value : ''}
            ]

            let check = this.checkFields();

            if(check){
                if(this.props.insertOMod === 'I'){
                    axios.post(`${process.env.REACT_APP_API_URL}/insertValutazione`, params, this.props.authParam).then((resp) => {
                        this.props.closeModalMod(true, null, this.props.idStudente);
                    }).catch((err) => {
                        this.props.setErrors(err);
                    })
                } else {
                    axios.post(`${process.env.REACT_APP_API_URL}/updateValutazione`, params, this.props.authParam).then((resp) => {
                        this.props.closeModalMod(true, null, this.props.idStudente);
                    }).catch((err) => {
                        this.props.setErrors(err);
                    })
                }
            } else {
                alert('inserisci tutti i campi obbligatori')
            }



           
        }

    }

    onChangeValue = (e, name) => {
        let dataModal = this.state.dataModal;
        let specials= name === 'email' ? /[*|\":<>[\]{}`\\()';&$]/ : /[*|\":<>[\]{}`\\()';@&$]/;
        if(!specials.test(e.target.value)){
            dataModal.fields.find(f => f.name === name).value = e.target.value;
            this.setState({ dataModal: dataModal })
        }

    }

    changeDateImm = (date, name) => {
        let dataModal = this.state.dataModal;
        let dateFormatted = date ? date : '';
        dataModal.fields.find(f => f.name === name).value = dateFormatted;
        this.setState({ dataModal: dataModal })
    }

    
    checkFields = () => {
    
        for(let i=0; i < this.state.dataModal.fields.length; i++){
            if(this.state.dataModal.fields[i].isObbligatorio && this.state.dataModal.fields[i].value === "") return false;
        }

        return true
    }



    render() {
        return (
            <Modal show={this.props.showModalUserData} onHide={() => this.props.closeModalMod(false)} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{ color: 'white' }}>
                        {this.props.insertOMod === 'M' ? 'MODIFICA' : 'INSERIMENTO'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {this.state.dataModal ? this.state.dataModal.fields.map((f, key) =>
                            f.type === 'input' ?
                                <Col key={key} xl={f.size}>
                                    <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            aria-label="Default"
                                            maxLength={f.maxLength ? f.maxLength : null}
                                            onChange={(e) => this.onChangeValue(e, f.name)}
                                            value={f.value}
                                            placeholder={f.label}
                                            aria-describedby="inputGroup-sizing-default"
                                        />
                                    </InputGroup>
                                </Col> :
                                f.type === 'optionCdl' ?
                                    <Col key={key} xl={f.size}>
                                        <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                        <select className='form-control' value={f.value} onChange={(e) => this.onChangeValue(e, f.name)}>
                                            <option></option>
                                            {this.state.codiciCdl ? this.state.codiciCdl.map(codice => <option value={codice.COD_CDL_COORTI} key={codice.COD_CDL_COORTI}>{codice.COD_CDL_COORTI}</option>) : <option></option>}
                                        </select>
                                    </Col> :
                                    f.type === 'optionLivelli' ?
                                        <Col key={key} xl={f.size}>
                                            <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                            <select className='form-control' onChange={(e) => this.onChangeValue(e, f.name)}value={f.value}>
                                                <option></option>
                                                {this.props.livelli ? this.props.livelli.map(lv => <option value={lv.ID_LIVELLO} key={lv.ID_LIVELLO}>{lv.LIVELLO}</option>) : <option></option>}
                                            </select>
                                        </Col> : 
                                        f.type === 'optionMailPT' ?
                                        <Col key={key} xl={f.size}>
                                            <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                            <select className='form-control' onChange={(e) => this.onChangeValue(e, f.name)}value={f.value === '' || f.value ==='N' ? 'N' : f.value === 'SCELTA NO PERCORSO SLAM' ? 'SCELTA NO PERCORSO SLAM' :  'Y'}>
                                                <option></option>
                                                <option value='Y'>Y</option>
                                                <option value='N'>N</option>
                                                <option value='SCELTA NO PERCORSO SLAM'>SCELTA NO PERCORSO SLAM</option>
                                            </select>
                                        </Col> :
                                         f.type === 'optionStatoStudente' ?
                                         <Col key={key} xl={f.size}>
                                             <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                             <select className='form-control' onChange={(e) => this.onChangeValue(e, f.name)}value={f.value}>
                                                 <option></option>
                                                 {this.state.statiStudente ? this.state.statiStudente.map(stato => <option value={stato.ID_STATO_STUDENTE} key={stato.ID_STATO_STUDENTE}>{stato.DESC_STATO}</option>) : <option></option>}
                                             </select>
                                         </Col>: 
                                        <Col key={key} xl={f.size}>
                                            <label className='customLabel' htmlFor="basic-url">{f.label}</label>
                                            <DatePicker
                                                className='datepicker'
                                                closeOnScroll={true}
                                                onChange={(date) => this.changeDateImm(date, f.name)}
                                                // minDate={}
                                                maxDate={new Date()}
                                                selected={f.value}
                                                //  startDate={this.props.filters.find(f => f.name === 'startDataImm').value}
                                                //  endDate={this.props.filters.find(f => f.name === 'endDataImm').value}
                                                //  selectsStart
                                                showYearDropdown
                                                showMonthDropdown
                                                dateFormat="dd/MM/yyyy"
                                                locale="it"
                                            />
                                        </Col>

                        ) : null}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={() => this.props.closeModalMod(false)}>
                        Annulla
                    </Button>
                    <Button onClick={this.saveData} variant="primary">
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalUserData;