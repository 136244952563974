import React from 'react';
import { Button, Spinner, Tabs, Tab, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck, faFileDownload, faSave, faHome, faCopy, faMailBulk } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Loader from '../components/Loader';
import SceltaSLAM from './SceltaSLAM';
import ModalMailingList from '../sections/ModalMailingList';

const loader = <Spinner animation="border" size="sm" role="status">
    <span className="sr-only">Loading...</span>
</Spinner>

class CheckConvocazioni extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            estrazioneLoading: false,
            reloadPage: false,
            showModalMailingList: false,
    
        }

        this.columns = [
            {
                name: 'MATRICOLA',
                selector: 'MATRICOLA',
                sortable: true,
                maxWidth: '1rem',
                cell: row => <div>{row.MATRICOLA}</div>,
            },
            {
                name: 'COD. CDL',
                selector: 'COD_CDL',
                sortable: true,
                cell: row => <div title={row.COD_CDL}>{row.COD_CDL}</div>,
                maxWidth: '1rem'
            },
            {
                name: 'COGNOME',
                selector: 'COGNOME',
                sortable: true,
                cell: row => <div title={row.COGNOME}>{row.COGNOME}</div>,
            },
            {
                name: 'NOME',
                selector: 'NOME',
                sortable: true,
                maxWidth: '10rem',
                cell: row => <div title={row.NOME}>{row.NOME}</div>,
            },
            {
                name: 'E-MAIL',
                selector: 'EMAIL',
                sortable: true,
                cell: row => <div title={row.EMAIL}>{row.EMAIL}</div>,
            }]



    }

    goBack = () => {
        this.setParentFilters().then(() => {
            this.props.history.goBack();
        })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    componentDidMount() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }

        this.getStudentiDaConvocare();


    }

    getStudentiDaConvocare = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getStudentiDaConvocare`, this.props.authParam).then((resp) => {
            this.setState({ studentiDaConvocare: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    callEstrazioneConvocazioni = () => {
        this.setState({ estrazioneLoading: true })
        axios.get(`${process.env.REACT_APP_API_URL}/callEstrazioneConvocazioniPT`, {
            responseType: 'arraybuffer',
            headers: this.props.authParam.headers
        }).then((resp) => {
            this.setState({ estrazioneLoading: false })
            var blob = new Blob([resp.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, `EmailPT_da_inviare_${moment(new Date()).format("DD MM YYYY hh:mm:ss")}.xlsx`);
        })
    }

    segnaConvocati = () => {
        if (window.confirm("Sei sicuro di voler segnare come inviate le mail PT")) {
            let IDs = this.state.studentiDaConvocare.map(sdc => {
                let mappedItem = [];
                mappedItem.push(sdc.ID);
                return mappedItem;
            })


            this.setState({ reloadPage: true })

            axios.post(`${process.env.REACT_APP_API_URL}/segnaConvocati`, IDs, this.props.authParam).then((resp) => {
                this.getStudentiDaConvocare();
                this.setState({ reloadPage: false })
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }
    }

    closeModalMailingList = () => {
        this.setState({ showModalMailingList: false })
    }

    hideModalMailingList = () => {
        // if (isSalvataggio) {
        //     this.reloadPage(idStudente, codCdl);
        // }
        this.setState({ showModalMailingList: false })
    }







    render() {
        return (
            <div>
                {/* <div><Button variant="primary mb-3" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Torna alla Home </Button></div> */}
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Invio email PT
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Tabs defaultActiveKey="invio" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="invio" title="Invio email PT">
                        <div className='mt-3'>
                            <h3>INVIO EMAIL PT</h3>
                            <p>In questa sezione è possibile verificare gli studenti che ancora non sono stati convocati per sostenere il Placement Test</p>
                        </div>
                        <div>
                            <Button variant="primary mr-2" disabled={!this.state.studentiDaConvocare || this.state.studentiDaConvocare.length === 0 || this.state.estrazioneLoading} onClick={this.callEstrazioneConvocazioni}> Scarica studenti da convocare {this.state.estrazioneLoading ? loader : <FontAwesomeIcon icon={faFileDownload} />}</Button>
                            <Button variant="secondary mr-2" disabled={!this.state.studentiDaConvocare || this.state.studentiDaConvocare.length === 0 || this.state.estrazioneLoading} onClick={()=> this.setState({showModalMailingList: true})}>Mailing List <FontAwesomeIcon icon={faMailBulk} /></Button>
                            <Button variant="success mr-2" disabled={!this.state.studentiDaConvocare || this.state.studentiDaConvocare.length === 0 || this.state.estrazioneLoading} onClick={this.segnaConvocati}> Spunta come convocati <FontAwesomeIcon icon={faCheck} /></Button>
                        </div>
                        {this.state.showModalMailingList ? <ModalMailingList  hideModalMailingList={this.hideModalMailingList} showModalMailingList={this.state.showModalMailingList} closeModalMailingList={this.closeModalMailingList} setErrors={this.props.setErrors} authParam={this.props.authParam} /> : null}
                        {!this.state.reloadPage && this.state.studentiDaConvocare ? <div>
                            <DataTable
                                className={'tableCustomStyle mt-3'}
                                columns={this.columns}
                                data={this.state.studentiDaConvocare}
                                pagination={true}
                                paginationPerPage={10}
                                striped
                                // onChangePage={this.props.onChangePage}
                                highlightOnHover
                                responsive
                            />
                        </div> : <Loader />}
                    </Tab>
                    <Tab eventKey="percorso_slam" title="Scelta percorso SLAM">
                        <SceltaSLAM setErrors={this.props.setErrors} authParam={this.props.authParam} />
                    </Tab>
                </Tabs>
            </div >)
    }
}

export default CheckConvocazioni;